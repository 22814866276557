/* RegisterForm.css */

.register-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
}
  
  .register-form-title {
    padding-top: 30px;
    color: #dd6532;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 112px;
    font-weight: 400;
    line-height: 134.4px;
    text-align: center;
    max-width: 778px;
    margin: 0;

    @media (max-width: 768px) {
     font-size: 56px;
      line-height: 67.2px;
    }
  }
  
.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 778px;
  width: 100%;
  padding: 73px;

  @media (max-width: 768px) {
    padding: 40px 20px;
}
}
  
  .message {
    position: fixed;
    top: 24px;
    right: 0;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }
  
  .message.success {
    background-color: green;
  }
  
  .message.error {
    background-color: red;
  }
  
  .form-error {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;
  }
  
  .form-group-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .form-group-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
  }
  
  .form-label {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  
  .form-group input {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    background-color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
  }
  
  .form-group input[type='text'],
  .form-group input[type='email'] {
    padding-right: 32px; 
  }
  
  .react-phone-number-input {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
  }
  
  .react-phone-number-input input {
    padding-right: 32px; 
  }
  
  .form-group-line {
    .form-group:first-child {
        margin-right: 4px;
    }

    .form-group:last-child {
        margin-left: 4px;
    }
 
  }

  .PhoneInput {
    background-color: #fff;
    border-radius: 8px;
    padding-left: 8px;
  }
  
  .register-form-button {
    margin-top: 60px;
  }