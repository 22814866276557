  
  .button {
    display: block;
    width: 100%;
    padding: 16px 28px;
    background-color: #dd6532;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    max-width: 242px;
    width: 100%;
  }

  .button:hover {
    background-color: #ff7f4d;
  }

  .homepage-wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }