.hero-banner {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #4363AF;
  width: 100%;
  min-height: 771px;
  height: auto;
  padding-right: 0;

  @media (max-width: 768px) {
    min-height: 450px;
    padding-bottom: 0;
    flex-direction: column;
  }
}


.hero-banner-img-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        max-width: 452px;
        max-height: 572px;
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    @media (max-width: 768px) {
        img {
            height: 100%;
        }
    }
}
  
.left-list, .right-list {
    overflow: hidden;
}
  
.left-list {
    height: 771px; /* Adjust height */
    display: flex;
    flex-direction: column;
}
  
.right-list {
    height: 771px; /* Adjust height */
    display: flex;
    flex-direction: column;
}
  
.left-list img, .right-list img {
    width: 100%;
    height: auto;
}
  
.left-list img {
    margin-bottom: 0px; /* Space between images */
}
  
.right-list img {
    margin-bottom: 0px; /* Space between images */
}

.img-glob-wrapper {
    position: absolute;
    bottom: -105px;
    left: 35vw;
    img {
        width: 100%;
        height: auto;
        max-width: 235px;
        max-height: 233px;
    }

    @media (max-width: 768px) {
        display: none;
    }
}

.hero-banner-text {
    position: absolute;
    z-index: 2;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    left: 60px;
    right: 0;
    top: 25%;
    color: #fff;

    .title {
        font-family: Bebas Neue;
        font-size: 112px;
        font-weight: 400;
        line-height: 134.4px;
        text-align: left;
        width: 100%;

        @media (max-width: 768px) {
            font-size: 80px;
            line-height: 80.2px;
        }
    }

    .title:first-child {
        color: #fff;
        text-shadow:
        2px 2px 0 #4363AF,  
        -2px -2px 0 #4363AF,
        2px -2px 0 #4363AF,
        -2px 2px 0 #4363AF;
    }

    .title:last-child {
        color: #DD6532;

    }

    .button {
        @media (max-width: 450px) {
            align-self: center;
        }
    }

    @media (max-width: 768px) {
        position: static;
        padding: 20px;
    }

}