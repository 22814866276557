.footer-wrapper {
    background-color: #fff;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    color: #000;
}

.footer-row-wrapper {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-column-wrapper {
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-image {
    width: 100%;
    height: auto;
    max-width: 107px;
    height: 29px;

   img {
    object-fit: cover;
    width: 100%;
    height: auto;
   }
}

.footer-gap {
    height: 30px;
}