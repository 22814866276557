.message-card-wrapper {
    max-width: 631px;
    min-height: 369px;
    width: 100%;
    height: auto;
    background-color: #DD6532;
    padding: 60px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.message-card-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    text-align: center;

}

.message-card-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

}