.image-text-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 30px;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.banner-wrapper {
    max-width: 395px;
    max-height: 893px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        max-width: 100%;
        min-height: 100%;
        max-height: 450px;
    }
}
  
.images-list {
    height: 1245px; /* Adjust height */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top:-60px;

    @media (max-width: 768px) {
        max-width: 100%;
        min-height: 100%;
        max-height: 450px;
        margin-top: 30px;
    }
}
  
.images-list img {
    width: 100%;
    height: auto;
}
  
.images-list img {
    margin-bottom: 0px; /* Space between images */
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 850px;
    width: 100%;
    padding: 0 30px;

    .title {
        font-family: Bebas Neue;
        font-size: 112px;
        font-weight: 400;
        line-height: 134.4px;
        text-align: left;
        color: #fff;
        width: 100%;
        text-shadow:
        2px 2px 0 #000,  
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000;
    }

    .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: justify;
        width: 100%;
        margin-bottom: 30px;
    }

    .button {
        margin-bottom: 30px;

        @media (max-width: 450px) {
            align-self: center;
        }
    }
}

.cols-wrapper {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    width: 100%;

    .col {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 280px;
        width: 100%;
    }

    .col-title {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;

    }
    .paragraph {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }   
}


@media (max-width: 768px) {
    .content-wrapper {
        padding: 0 15px;

        .title {
            font-size: 56px;
            line-height: 67.2px;
            text-align: center;
        }

        .cols-wrapper {
            flex-direction: column;
            justify-content: center;
            gap: 30px;

            .col {
                display: flex;
                max-width: 100%;
                justify-content: center;

                .col-image {
                    display: flex;
                    align-self: center;
                }

                .col-title {
                    text-align: center;
                }
            }

            .paragraph {
                text-align: left;
            }
        }
    }
}