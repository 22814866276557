.spinner {
    width: 100%;
    height: 100%;
    position: fixed; 
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8); 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    z-index: 9999; 
}

.rotating-image {
    width: 120px;
    height: 120px;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
